//@import 'https://fonts.googleapis.com/css?family=Libre+Baskerville:400,400i|Lato:300,300i,400';
@import 'https://fonts.googleapis.com/css?family=Libre+Baskerville:400,400i|Open+Sans:300,300i,400';

$theme-grey: #736b6b;
$theme-black: #000000;
$white-grey: #e4e3e0;
$light-grey: #adaba6;
$dark-grey: #6e6d69;
$off-white: #edecec;

body{
	background:#FFFFFF;
	color: $theme-grey;
	text-align:center;
	font-family: 'Open Sans', sans-serif;
	font-weight:300;
	a{
		color:#FFFFFF;
		&:active, &:visited, &:hover, a:focus{
			color:#FFFFFF;
			text-decoration: none;

		}
	}
	h1{
		font-size: 3rem;
	}
	h2{
		font-size:2.6rem;
		font-weight: 300;
	}
	h3{
		font-size: 2.4rem;
		font-style: italic;

	}
	p{
		line-height: 2.4rem;
		margin-top:1.6rem;
	}
	span{
		&.price-black{
			color:$theme-black;
			font-weight: 700;
		}
	}

	.section{
		margin-top:4rem;
		margin-bottom:4rem;
		overflow: hidden;
	}
	.box-link{
		padding: 1rem 0rem;
	    background: #000000;
	    border: 1px solid #FFFFFF;
	    font-size: 2rem;
	    text-transform: uppercase;
	    margin: 2rem auto;
	    display: block;
	    text-align: center;
	    display: inline-block;
	    color: #FFFFFF;
	    &:hover{
	    	font-weight:bold;
	    }
	}
	.box-link-small{
		margin: 0;
		padding:0.5rem 0rem;
		font-size: 1.6rem;

	}
	.box-link-tiny {
	    margin: 0;
	    padding: 0.2rem 0rem;
	    font-size: 1rem;
	    color: white;
	    cursor: pointer;


	}
	.featured{
	    border: 1px solid #736b6b;
	    padding: 1.4rem 8rem;
	    text-transform: uppercase;
	    font-size: 2.4rem;
	    display: inline-block;
	    font-weight: 300;
	    margin-top: 2.8rem;
	    position: relative;
	    letter-spacing: 0.1rem;

		span{
			position:absolute;
			font-style: italic;
			font-family: 'Libre Baskerville', serif;
			text-transform: none;
			display: block;
			background:#FFFFFF;
		    width: 18rem;
		    left: 0;
		    right: 0;
		    margin: auto;

		}
	}
	.social-link{
		    width: 8rem;
    margin: 0 auto;
    display: inline-block;
	}
	.overflow-shadow{
		-webkit-box-shadow: inset 0 -7px 9px -7px rgba(0,0,0,0.4);
		-moz-box-shadow: inset 0 -7px 9px -7px rgba(0,0,0,0.4);
		box-shadow: inset 0 -7px 9px -7px rgba(0,0,0,0.4);
	}

	/* navbar stuff */
	.navbar{
		padding-top: 1rem;
		position:absolute;
		width:100%;
		&.dark{
			background: rgba(0,0,0,.2);
			position: inherit;
		}
		.navbar-brand{
			img{
				width:20rem;
				position: absolute;
			    top: 2rem;
			    left: 50%;
			    margin-left: -10rem!important;
			}
		}
		#navbar{
			margin: 2rem 0 3rem 0;
			text-align:center;
		}
		.navbar-nav{
			display: inline-block;
			float: none;
			width: 600px;
    		li{
    			text-transform: uppercase;
    			margin:0;
    			font-weight: 400;
				letter-spacing: 0.1rem;
				width: 200px;
    			&:nth-child(1){
    				float:left
    			}
    			&:nth-child(2){
    				float:right;
    			}
    			a{
    				padding-left:3rem;
					padding-right:3rem;
					color: #000!important;
    				&:hover{
						color:darken(#FFFFFF, 10% );
						background:none;
						font-weight: 700;
						background: rgba(0,0,0,.2);

					}
					&:focus{
						background:transparent;
						border:none;
						outline: none;
					}
    			}
    			&.dropdown{
    				a{
    					background:transparent!important;
    					&:hover{
    						background: rgba(0,0,0,.2)!important;
    					}
    				}
    				.dropdown-toggle{
    					color:#FFFFFF;
    				}
    				
    				
    				.dropdown-menu{
    					background:#000000;
    					width: 100%;
    					position: absolute;
    					li{
    						margin: 1rem 0 !important;

    						a{
    							color:#FFFFFF!important;
    						}
    					}
    					&:after {
							bottom: 100%;
							left: 50%;
							border: solid transparent;
							content: " ";
							height: 0;
							width: 0;
							position: absolute;
							pointer-events: none;
							border-color: rgba(0, 0, 0, 0);
							border-bottom-color: #000;
							border-width: 10px;
							margin-left: -10px;
							
						}
    				}
    			}

    		}

		}
	}
	
	/*=================================
	=            home page            =
	=================================*/
	

	

	#home-intro{
		height: 60vh;
		background-image: url('/imgs/headers/adidas-court-header.jpg');
		background-repeat: none;
		background-size: cover;
		background-position:center;
		color:#FFFFFF;
		h1{
			margin: auto;
		    width: 60rem;
		    text-align: center;
		    padding-top: 35vh;
		    text-transform: uppercase;
		    font-size: 6rem;
		    font-weight: 300;
		}
		span{
			margin: 1rem auto;
		    width: 60rem;
		    text-align: center;
		    font-style: italic;
		    font-size: 4rem;
		    font-weight: 300;
		    display: block;
		    font-family: 'Libre Baskerville', serif;
		}

	}
	#home-main{
		h2{
			margin-top: 3rem;
    		margin-bottom: 0rem;
		}
		h3{
			margin-top: 0.6rem;
		}
	}
	#home-featured{
		margin-top: 1rem;
		#featured-item-wrapper{
			overflow: hidden;
		}
	}
	#home-review{
		margin-top: 6rem;

		p{
			font-size: 2.6rem;
    		font-style: italic;
		}
		#review-wrapper{
			border: 1px solid $theme-grey;
		    display: inline-block;
		    padding: 5rem 3rem 2rem 3rem;
		    margin-top: 2rem;
		    width: 60%;
		    position:relative;
			h3{
				font-size: 3.4rem;
			    font-style: normal;
			    font-weight: 300;
			    background: #FFFFFF;
			    position: absolute;
			    left: 0;
			    right: 0;
			    top: -2rem;
			    width: 62rem;
			    margin: auto;
			}
			#review-image{
				
			}
			#review-content{
				span{
					font-family: 'Libre Baskerville', serif;
					text-align:left;
					display: block;
					font-style: italic;
					font-size:2rem;
					font-weight: bold;
				}
				p{
					margin-top: 0.5rem;
					font-size: 1.6rem;
				    text-align: left;
				    font-family: 'Libre Baskerville', serif;
				    line-height: 2.6rem;

				}
				.review-stars{
					float: left;
					

				}
			}
			a{
				display: inline-block;
			    position: absolute;
			    bottom: 1rem;
			    right: 2rem;
			}

		}
		
	}
	#home-grey-boxes{
		margin:6rem 0;
		text-align: center;
		div{
			height: 0;
		    width: 26%;
		    display: inline-block;
		    padding: 0 0 26% 0;
		    margin: 1rem;
			color:white;
			overflow: hidden;
			position: relative;
			z-index: 1;
			span{

			    text-transform: uppercase;
			    font-size: 4rem;
			    line-height: 8rem;
			    display: table-cell;
			    vertical-align: middle;
			    /* height: 100%; */
			    /* height: 30rem; */
			    padding: 4rem;
			    position: absolute;
			    z-index: 2;
			    top: 10%;
			    left: 10%;
			    bottom: 10%;
			    right: 10%;
			    border: 1px solid white;
			}
			&.overlay{
				position: absolute;
			    height: 100%;
			    width: 100%;
			    top: 0;
			    left: 0;
			    background-color: rgba(0,0,0,.5);
			    padding: 0;
			    margin: 0;
			    z-index: 1;
			}
		}
		.light-grey{
			background:$light-grey;
		}
		.dark-grey{
			background:$dark-grey;
		}
		.award{
			background-image: url(/imgs/home/amy-award.jpg);
		    background-repeat: no-repeat;
		    background-size: cover;
		    background-position-x: 50%;
		    
		}
		.bespoke{
			background-image: url(/imgs/home/bespoke-home.jpg);
		    background-repeat: no-repeat;
		    background-size: cover;
		    background-position-x: 50%;
		    
		}

	}
	#home-wow{
		margin:4rem auto;
		#wow-box{
			text-align: center;
		    border: 1px solid #736b6b;
		    display: inline-block;
		    font-size: 3rem;
		    word-spacing: 3rem;
		    padding: 1.6rem 4rem;
		    line-height: 4rem;
			p{
				word-spacing: normal;
			    font-size: 2.6rem;
			    font-style: italic;
			    font-family: 'Libre Baskerville', serif;
			}
		}

	}

	/*=====  End of home page  ======*/



	/*====================================
	=            product page            =
	====================================*/

	
	#products-intro{
		margin-top: 6rem;
    	margin-bottom: 5rem;
    	color: $dark-grey;
    	
		h1{
			text-transform: uppercase;

    		color: $theme-black;
			span{
				font-family: 'Libre Baskerville', serif;
				font-style: italic;
				text-transform:none;
			}
		}
	}
	#product-list, #brand-list{
		.product-item-wrapper{
			overflow: hidden;
			margin-bottom:3rem;

		}

		.product-item{
   			box-sizing: border-box;
			position:relative;
			
			padding:6px;
			border-radius:6px;
			-webkit-box-shadow: inset 0px 0px 10px 0px rgba(0,0,0,0.3);
			-moz-box-shadow: inset 0px 0px 10px 0px rgba(0,0,0,0.3);
			box-shadow: inset 0px 0px 10px 0px rgba(0,0,0,0.3);
			a{
				img{
					width:100%;
					&:hover{
						-moz-transition: all .2s ease-in-out;
		    -webkit-transition: all .2s ease-in-out;
		    transition: all .2s ease-in-out;
						-moz-transform: scale(1.05);
						-webkit-transform: scale(1.05);
						transform: scale(1.05);
					}
				}
			}
			.overlay{
				background: rgba(0,0,0,0.35);
			    bottom: 0;
			    left: 0;
			    position: absolute;
			    right: 0;
			    top: 0;
			}
			.product-info{
				color: #fff;
			    left: 0;
			    position: absolute;
			    right: 0;
			    top: 80%;
			    -moz-transform: translateY(-50%);
			    -webkit-transform: translateY(-50%);
			    transform: translateY(-50%);
			    .name{
			    	font-family: 'Libre Baskerville', serif;
    				font-size: 2.6rem;
			    }
			    .type{
			    	font-size: 2rem;
			    }
			}
		}
	}

	#product-item{
		margin-top: 4rem;
    	text-align: left;
    	color: $theme-black;
    	#product-left, #product-right{
    		padding:1rem 2.6rem;
    	}
    	
    	h1{
    		margin-top: 0;
    		text-transform: uppercase;
    		font-size:2.4rem;
    		color: $theme-black;
		}
		h2{
			color: $light-grey;
			margin-top: -0.8rem;
			font-size: 1.6rem;
		}
		#product-price{
			text-transform: uppercase;
			margin-top: -0.6rem;
			color: $theme-black;
			
    		span{
    			font-size: 2rem;
    			font-weight: 700;
    		}
		}
		#full-product-description{
			margin-top: 0.4rem;
			display: none;

		}
		#product-description{
			/*max-height:8rem;*/
			overflow: hidden;
			margin-top: 0.4rem;
		}
		.description-link{

		    color: #000000;
		    text-decoration: underline;
		    font-weight: 700;
		    line-height: 200%;
		}
		#price-calculator{
			//background-color:$off-white;
			border-top:0.1rem solid $dark-grey;
			border-bottom:0.1rem solid $dark-grey;
			padding: 2rem 0;
			margin-top: 1.8rem;
			margin-bottom: 1.8rem;

			h3{
				text-transform: uppercase;
				margin:0;
				font-style:normal;
				font-size: 1.8rem;
				font-weight: 700;
				color: $theme-black;
			}
			.price-label{
				text-transform: uppercase;
				margin-top:1rem;
				font-weight:700;
				color: $theme-black;
				margin-bottom: 0.4em;
			}
			#product-quantity{
				background:$white-grey;
    				border:none;
    				padding: 0.6rem;
					width: 6rem;
					margin-left: 0.4rem;
			}
			.iradio_flat{
				margin-right: 0.6rem;
			}
			label{
				margin-right: 0.6rem;
				font-weight: 300;
				max-width: 90%;
    			vertical-align: text-top;
    			/** this was for the product options **/
			}
			.price-box{
				display: inline-block;
    			margin-left: 1rem;

			}
			#total-amount{
				margin-top: 2rem;
			}
			#send-price-enquiry{
				margin-top: 2rem;
				color: white;
			}

		}
		#day-stationary{
			background-image: url("/imgs/icons/tick.png");
			background-repeat: no-repeat;
			padding-left:2.6rem;
			background-position-y: 0.2rem;
		}
		.heading-label{
			text-transform: uppercase;
			margin-top:1rem;
			font-weight:700;
			color: $theme-black;
			margin-bottom: 0.4em;
		}
		#product-similar{
			text-align: center;
			margin-bottom:6rem;
		}
		.text-link{
			text-transform: uppercase;
			color: $theme-black;
			text-decoration: underline;
		    font-weight: 700;
		    line-height: 200%;
		}
		#sample-wrapper{
			padding-bottom: 1rem;
    		margin-bottom: 1.6rem;
    		border-bottom:1px solid $theme-grey;
		}
		#thumb-wrapper{
			.image_thumbs{
				width:25%;
				float: left;
				padding:0.2rem;
				cursor: pointer;
				img{

				}
			}
		}
		.owl-nav{
			position: absolute;
    		top: 50%;
    		width: 100%;
    		div{
    			margin: 0 1rem;
			    font-size: 4rem;
			    color: white;
			    display: inline-block;
			    &.arrow{
			    	i{
			    		cursor: pointer;
			    	}
			    	
			    }
    		}
		}
		.owl-item{
    		margin-top:1rem;
    		cursor: normal;
    	}
	}

	#btt{
		width: 6rem;
	    right: 1rem;
	    bottom: 1rem;
	    z-index: 99;
	    background: rgba(0,0,0,0.6);
	    color: white;
	    position: fixed;
	    padding: 1rem;
	    border-radius: 1rem;
	    cursor: pointer;
	    display: none;
	    &.show{
	    	display: block;
	    }
	}

	/*=====  End of product page  ======*/



	/*==================================
	=            about page            =
	==================================*/
	
	#about-intro{

		h1{
			text-transform: uppercase;
			color: $theme-black;
		}
		.subheader{
			margin-top: -0.4rem;
		}
		#about-head-wrapper{
			margin-top: 4rem;
			#about-image{
				margin-bottom: 2rem;
			}
			#about-text{
				text-align: left;
				p:nth-child(1){
					margin-top: -0.6rem;
				}
			}
		}
		
	}

	#about-grey-boxes{
		margin-top:6rem;
		margin-bottom:6rem;
		text-align: center;
		color: $theme-black;
		
		.grey-box{
			position: relative;
			background-position: 50% 2rem !important;
			background-repeat:no-repeat!important;
			overflow: auto;
			margin-left:-1rem;
			margin-right:-1rem;
			margin-bottom:1rem;
			min-height: 26rem;
   			//background-image: url("/imgs/icons/check.svg")!important;
   			//background-size: 20%!important;
			span{
				display: block;
			    font-size: 2.6rem;
			    padding-top: 4rem;
			}
			
		}
		.light-grey{
			background:$light-grey;
		}
		.white-grey{
			background: #f8f8f8;;
		}
		.dark-grey{
			background:$dark-grey;
		}
		.text-wrapper{
			width: 90%;
		    margin: 1rem 5% 3rem;
		    overflow: visible;
		}

	}
	
	/*=====  End of about page  ======*/
	



	/*====================================
	=            bespoke page            =
	====================================*/
	
	#bespoke-intro{

		h1{
			text-transform: uppercase;
			color: $theme-black;
		}
		.subheader{
			margin-top: -0.4rem;
		}

		#bespoke-images{
			margin-bottom:6rem;
			margin-top: 2.6rem;
			overflow: auto;
			.bespoke-image{
				min-height:40rem;
				background: $light-grey;
				background-repeat:no-repeat;
				background-size: cover;
				background-position:center;
				&.sketch{
					background-image: url("/imgs/bespoke/sketch.jpg");
				}
				&.passport{
					background-image: url("/imgs/bespoke/passport.jpg");
					background-size: 130%!important;
				}
				&.ribbon{
					background-image: url("/imgs/bespoke/ribbon.jpg");
					background-size: 130%!important;
					background-position:top center;
				}
			}
		}
		#bespoke-text{
			max-width: 60rem;
		    margin: 0 auto;
		    h3{
		    	font-size:2rem;
		    }
		}
	}

	#bespoke-grey-boxes{
		margin-top:6rem;
		margin-bottom:6rem;
		text-align: center;
		color: white;
  		
		.grey-box{
			position: relative;
			background-position: 50% 2rem !important;
			background-repeat:no-repeat!important;
			overflow: auto;
			margin-left:-1rem;
			margin-right:-1rem;
			margin-bottom:1rem;
			min-height: 38rem;
			span{
				display: block;
			    font-size: 2.6rem;
			    padding-top: 14rem;
			}
			&.consultation{
				background-image: url("/imgs/bespoke/consultation-icon.png");
			}
			&.design{
				background-image: url("/imgs/bespoke/design-icon.png");
			}
			&.sample{
				background-image: url("/imgs/bespoke/sample-icon.png");
				background-position: 50% 3.4rem !important;
			}
			&.production{
				background-image: url("/imgs/bespoke/production-icon.png");
			}
		}
		.light-grey{
			background:$light-grey;
		}
		.dark-grey{
			background:$dark-grey;
		}
		.text-wrapper{
			width: 90%;
		    margin: 1rem 5% 3rem;
		    overflow: visible;
		}

	}
	
	/*=====  End of bespoke page  ======*/
	
	/*====================================
	=            Reviews Page            =
	====================================*/
	
	#review-intro{

		h1{
			text-transform: uppercase;
			color: $theme-black;
		}
		.subheader{
			margin-top: -0.4rem;
		}

	}
	#reviews-wrapper{
		margin-top: 4rem;
		margin-bottom: 4rem;
		.review{
			width: 100%;
			overflow: auto;
			margin-bottom: 2rem;
			//border-bottom:1px solid $light-grey;
			.review-text{
				margin-top: 0.6rem;
				text-align: left!important;
				//height:25rem;
				//overflow: hidden;
				font-family: 'Libre Baskerville', serif!important;
				h2{

				}
				.review-name{
					font-size:1.8rem;
					font-style: italic;
				}
				.review-full{
					display: none;
				}
			}
			.read-more, .read-less{
			    text-decoration: underline;
			    color: $theme-black;
			    position: relative;
			    cursor: pointer;
			    
			}

		}
	}
	
	/*=====  End of Reviews Page  ======*/
	

	/*===================================
	=            sample page            =
	===================================*/
	
	#sample-product{

		h1{
			text-transform: uppercase;
			color: $theme-black;
		}
		.subheader{
			margin-top: -0.4rem;
		}



	}
	
	/*=====  End of sample page  ======*/

	/*====================================
	=            contact page            =
	====================================*/
	
	#contact-page{

		h1{
			text-transform: uppercase;
			color: $theme-black;
		}
		.subheader{
			margin-top: -0.4rem;
		}
		#product-enquiry-image{
			float: left;
		}
		#product-enquiry-breakdown{
			text-align: left;
			float: left;
			.iradio_flat{
				margin-right: 0.6rem;
			}
			label{
				margin-right: 0.6rem;
			    font-weight: 300;
			    max-width: 90%;
			    vertical-align: text-top;
			    display: inline;
			}
			.price-label{
				text-transform: uppercase;
				margin-top:1rem;
				font-weight:700;
				color: $theme-black;
				margin-bottom: 0.4em;
			}
		}
		#enquiry-payment-disclaimer{
			margin-top: 2rem;
			padding-bottom:2rem;
			border-bottom:1px solid $theme-grey;
			float: left;
		}
		#contact-basic{
			margin-top: 3rem;
			.form-group{
				label{
					float: left;
					text-transform: uppercase;
				}
				span{
					display: block;
				    float: left;
				    margin-bottom: 2.2rem;
				    font-style: italic;
				}
			}
			#send-button-wrapper{
				margin-top: 2rem;
			}
			
		}

	}
	
	/*=====  End of contact page  ======*/
	
	
	
	
	
	
	
	/*==============================
	=            footer            =
	==============================*/
	
	footer{
		background:$theme-grey;
		color: #FFFFFF;
		padding:4rem;
		margin-top: 4rem;
		#footer-social-icons{
			a{
				
				img{
					width:5rem;
					height:5rem;
					margin:1rem; 
				}
			}
		}
	}
	
	/*=====  End of footer  ======*/
	
	
}
