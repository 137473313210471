

$theme-grey: #736b6b;
$theme-black: #000000;
$white-grey: #e4e3e0;
$light-grey: #adaba6;
$dark-grey: #6e6d69;
$off-white: #edecec;

/*==========  Non-Mobile First Method  ==========*/

/* Large Devices, Wide Screens */
@media only screen and (max-width : 1200px) {

	body{
		#home-grey-boxes{
			div{
				width:45%;
				min-height: 40rem;
			}
		}
		.navbar{
			min-height:8rem!important;
			background: rgba(0,0,0,.7);
			.navbar-brand{
				img{
					width: 14rem!important;
				    position: absolute!important;
				    top: .8rem!important;
				    left: 50%!important;
				    margin-left: -8rem !important;
				}
			}
			.navbar-nav{
				margin:0 auto!important;
				padding-top: 1rem;
				width: 90%;
				li{
					&:nth-child(2){
						margin-right: 8rem;
					}
					&:nth-child(3){
						margin-left: 8rem;
					}
					&:nth-child(1){
						margin-left: -4.6rem;
					}
					a{
						padding-left: 2rem;
    					padding-right: 2rem;
					}
					
				}
			}
			#navbar{
				margin:0!important;
			}
		}

		#home-review{
			margin-left:1.5rem;
			margin-right: 1.5rem;
			#review-wrapper{
				width: 90%;
				
			}
			
		}


		#bespoke-grey-boxes{
			.grey-box{
				min-height: 42rem;
			}
		}

		#about-grey-boxes{

		
		.grey-box{

			min-height: 28rem;
   			//background-image: url("/imgs/icons/check.svg")!important;
   			//background-size: 20%!important;
		}

	}


		#product-item{
			#product-left{
				margin-bottom: 2rem;
				padding:1rem 0rem;
			}
			#product-right{
				padding: 1rem 1rem 1rem 2.6rem;
				#product-enquiry-breakdown{
					margin-top: 2rem;
			    	float: left;
				}
				#price-calculator{
					small{
						display: block;
					}
				}
			}
		}

		#contact-basic{
			#extra-info{
				text-align: left;
			}
		}

		footer{
			padding: 6rem 1.5rem;
		}
	}

}

/* Medium Devices, Desktops */
@media only screen and (width : 768px){
 body{
 	.navbar{
			min-height:8rem!important;
			background: rgba(0,0,0,.7);
			.navbar-brand{
				img{
					width: 10rem!important;
				    position: absolute!important;
				    top: 2rem!important;
				    left: 50%!important;
				    margin-left: -5rem !important;
				}
			}
			.navbar-nav{
				margin:0!important;
				padding-top: 1rem!important;
				width: 100%!important;
				li{
					&:nth-child(1){
						margin-left:0!important;
					}
					&:nth-child(2){
						margin-right: 6rem!important;
					}
					&:nth-child(3){
						margin-left: 6rem!important;
					}
					a{
						padding-left: 1rem!important;
					    padding-right: 1rem!important;
					    font-size: 1.1rem!important;
					}
				}
			}
			#navbar{
				margin:0!important;
			}
		}
	}

	#bespoke-grey-boxes{
			.grey-box{
				min-height: 38rem;
			}
		}

		#about-grey-boxes{

		
		.grey-box{

			min-height: 23rem;
   			//background-image: url("/imgs/icons/check.svg")!important;
   			//background-size: 20%!important;
		}

	}


}



/* Small Devices, Tablets */
@media only screen and (max-width : 767px) {

	body{
		.featured{
			margin-left:1.5rem;
			margin-right:1.5rem;
		}
		#home-intro{
			height:75vh;
			h1{
				width:100%;
				font-size:3.6rem;
			}
			span{
				width:100%;
				font-size:3rem;
			}
		}
		#home-review{
			margin-left:1.5rem;
			margin-right: 1.5rem;
			#review-wrapper{
				width: 100%;
				h3{
					font-size: 2rem;
					width: 80%;
				}
				#review-image{
					margin-bottom: 2rem;
				}
				.review-stars{
					margin-bottom: 4rem;
					width: 20rem;
				}
				a{
					bottom:0;
				}
			}
			p{
				margin-bottom: 1rem;
				font-size: 2rem;
			}
		}
		#home-grey-boxes{
			div{
				width:45%;
				min-height: 40rem;
			}
		}
		#home-wow{
			margin-left:1.5rem;
			margin-right:1.5rem;
			#wow-box{
				padding: 1.8rem;
			}
		}

		#products-intro{
			h1{
			    font-size: 2.6rem;
			    span{
			    	display: block;
			    }
			}
		}

		#product-item{
			#product-left{
				margin-bottom: 2rem;
				padding:1rem 0rem;
			}
			#product-right{
				padding: 1rem 1rem 1rem 2.6rem;
				#product-enquiry-breakdown{
					margin-top: 2rem;
			    	float: left;
				}
				#price-calculator{
					small{
						display: block;
					}
				}
			}
		}

		#about-head-wrapper{
			padding-left:0;
			padding-right:0;
		}

		#about-intro, #bespoke-intro, #contact-page, #review-intro{
			h1{
				font-size: 2.6rem;
			}
		}

		
		
		.navbar{
			min-height:8rem!important;
			background: rgba(0,0,0,.7);
			.navbar-brand{
				img{
					width: 16rem!important;
				    position: absolute!important;
				    top: .8rem!important;
				    left: 50%!important;
				    margin-left: -8rem !important;
				}
			}
			.navbar-nav{
				margin:0!important;
				padding-top: 1rem;
				li{
					&:nth-child(1), &:nth-child(2), &:nth-child(3){
						margin:0!important;
					}
					&.dropdown{

						.dropdown-menu{
				
							position: relative;
							text-align: center;
							background: transparent;
							    padding: 0;
							    margin:0;
							li{
								margin:0!important;
								a{
									padding:1rem 0;
								}
							}
							&:after{
								content: none;
							}
						}
					}
				}
			}
			#navbar{
				margin:0!important;
			}
		}
		footer{
			padding: 6rem 1.5rem;
		}
	}

}

/* Extra Small Devices, Phones */ 
@media only screen and (max-width : 480px) {

	body{
		.featured{
			margin-left:1.5rem;
			margin-right:1.5rem;
		}
		#home-intro{
			h1{
				width:100%;
				font-size:3.6rem;
			}
			span{
				width:100%;
				font-size:3rem;
			}
		}
		#home-review{
			margin-left:1.5rem;
			margin-right: 1.5rem;
			#review-wrapper{
				width: 100%;
				h3{
					font-size: 2rem;
					width: 80%;
				}
				#review-image{
					margin-bottom: 2rem;
				}
				.review-stars{
					padding-bottom:2rem;
					img{
						width:20rem;
					}
				}
			}
			p{
				margin-bottom: 1rem;
				font-size: 2rem;
			}
		}
		#home-grey-boxes{
			div{
				width:90%;
				min-height: 40rem;
			}
		}
		#home-wow{
			margin-left:1.5rem;
			margin-right:1.5rem;
			#wow-box{
				padding: 1.8rem;
				font-size: 2.6rem;
    			word-spacing: 1rem;
			}
		}

		#products-intro{
			h1{
			    font-size: 2.6rem;
			    span{
			    	display: block;
			    }
			}
		}

		#product-item{
			margin-top: 0rem;
			#product-left{
				margin-bottom: 0rem;
				padding:1rem 0rem;
				    overflow: auto;
			}
			#product-right{
				padding:1rem 0rem;
				#product-enquiry-breakdown{
					margin-top: 2rem;
			    	float: left;
				}
				#price-calculator{
					small{
						display: block;
					}
				}
			}
		}

		#about-head-wrapper{
			padding-left:0;
			padding-right:0;
		}

		#about-intro, #bespoke-intro, #contact-page, #review-intro{
			h1{
				font-size: 2.6rem;
			}
		}

		
		.navbar{
			min-height:8rem!important;
			background: rgba(0,0,0,.7);
			.navbar-brand{
				img{
					width: 16rem!important;
				    position: absolute!important;
				    top: .8rem!important;
				    left: 50%!important;
				    margin-left: -8rem !important;
				}
			}
			.navbar-nav{
				margin:0!important;
				padding-top: 1rem;
				li{
					&:nth-child(1), &:nth-child(2), &:nth-child(3){
						margin:0!important;
					}
					&.dropdown{

						.dropdown-menu{
				
							position: relative;
							text-align: center;
							background: transparent;
							    padding: 0;
							    margin:0;
							li{
								margin:0!important;
								a{
									padding:1rem 0;
								}
							}
							&:after{
								content: none;
							}
						}
					}
				}
			}
			#navbar{
				margin:0!important;
			}
			.navbar-toggle{
				    margin-top: 12px;
			}
		}
		footer{
			padding: 6rem 1.5rem;
		}
	}

}

/* Custom, iPhone Retina */ 
@media only screen and (max-width : 320px) {
    
}